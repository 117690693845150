import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from "react-router-dom";



export const PrivateRoute = ({ children,
    estaLoginat,
    ...rest
}) => {

    return (
          (estaLoginat) ? children : <Navigate to="/login" />
    )
}

PrivateRoute.propTypes = {
    estaLoginat: PropTypes.bool.isRequired
}
